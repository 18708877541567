import React,{useContext} from 'react';
import { GlobalDataContext } from '../context/context';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactPage from './section-components/contact-page';
import Footer from './global-components/footer';


function Reviews() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
        <Navbar />
        <PageHeader headertitle="Reviews" Subheader="Reviews" backgroundImage={rpdata?.gallery?.[5]} />
        <div className="text-center container">
                <h2 className="text-center pd-top-60">Our Reviews</h2>
                <div class="elfsight-app-f3d58963-b0b9-4b4e-8f7b-8fde04dce5ef"></div>
            </div>
        <Footer />
        </>
    );
}

export default Reviews;
