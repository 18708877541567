import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';

function Button_Qr(){

    const { rpdata } = useContext(GlobalDataContext); 
    const [showModal, setShowModal] = React.useState(false); 

    return(
        <div className=''>
            <div className='qrcode'> <img
                src={rpdata?.qrImg}
                alt="qr images"
                className="rounded-lg"
            /> </div> 
        </div>
    );
    

}  

export default Button_Qr;