import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const Directories = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center text-center pd-top-80">
        <a href={rpdata?.gmb?.link} target="blank">
            <img src={rpdata?.gmb?.img} class="imgGb" style={{width:'80%'}}/>
          </a>
        </div>
        <div className="row justify-content-center pd-top-50"></div>
        <h2 className="pd-top-100 text-center">Find Us</h2>
        <div className="row justify-content-center pd-top-20">
          { rpdata?.dbSocialMedia?.directorios.map((item, index) => {
            return (
              <div
                className="col-lg-2 col-md-6 text-center content-directorios"
                key={index}
              >
                <a href={item.url} target="_blank">
                  <img src={item.image}></img>
                </a>
              </div>
            );
          })} 
          {rpdata?.dbSocialMedia?.redes.map((item, index) => {
            return (
              <div
                className="col-lg-2 col-md-6 text-center content-directorios"
                key={index}
              >
                <a href={item.url} target="_blank">
                  <img src={item.image}></img>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Directories;